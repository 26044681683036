import React from "react";
import PropTypes from "prop-types";
import { Link, graphql } from "gatsby";

import Layout from "../components/Layout";
import Features from "../components/Features";
import BlogRoll from "../components/BlogRoll";

export const IndexPageTemplate = ({
  image,
  title,
  subtitle,
  heading,
  tel,
  mail,
  avatar
}) => (
  <div>
    <header
      style={{
        backgroundImage: `linear-gradient(
          rgba(0, 0, 0, 0.3),
          rgba(0, 0, 0, 0.3)
        ), url(${image})`,
        backgroundPosition: `top left`,
        backgroundAttachment: `fixed`,
        height: "80vh"
      }}
      className="hero"
    >
      <div>
        <div className="title-container">
          <div className="title">{title}</div>
        </div>
        <div className="subtitle">{subtitle}</div>
      </div>
    </header>
    <div className="content">
      <section>
        <div className="catch">{heading}</div>
      </section>
      <section>
        <h1>Mes derniers projets</h1>
        <BlogRoll />
        <Link className="btn see-more" to="/projects">
          Voir tous mes projets
        </Link>
      </section>
      <section>
        <h1>Me contacter</h1>
        <div className="contact-me">
          <img src={`${avatar}-/scale_crop/160x160/smart`} className="avatar" />
          <div className="info">
            <p>Ogier VOINOT</p>
            <p>
              <a href={`tel:${tel}`}>{tel}</a>
            </p>
            <p>
              <a href={`mailto:${mail}`}>{mail}</a>
            </p>
          </div>
        </div>
      </section>
    </div>
  </div>
);

IndexPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  subtitle: PropTypes.string,
  heading: PropTypes.string,
  tel: PropTypes.string,
  mail: PropTypes.object,
  avatar: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
};

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <Layout>
      <IndexPageTemplate
        image={frontmatter.image}
        title={frontmatter.title}
        subtitle={frontmatter.subtitle}
        heading={frontmatter.heading}
        tel={frontmatter.tel}
        mail={frontmatter.mail}
        avatar={frontmatter.avatar}
      />
    </Layout>
  );
};

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object
    })
  })
};

export default IndexPage;

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        title
        subtitle
        image
        heading
        tel
        mail
        avatar
      }
    }
  }
`;
